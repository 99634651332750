.editor

  // @apply text-xs md:text-sm xl:text-base
  @apply text-xs md:text-sm xl:text-base

  hr
    @apply my-6 md:my-8

  h1
    @apply text-base_headline md:text-lg 2xl:text-xl font-bold
    @apply uppercase tracking-wider
    @apply mb-[0.5em]
    hypens: auto

  h2
    @apply text-base_headline md:text-lg 2xl:text-xl font-bold
    @apply uppercase tracking-wider
    @apply mb-[0.5em] mt-[2em]
    hypens: auto

  h3
    @apply uppercase tracking-wider font-bold
    @apply mb-[0.5em] mt-[1em]
    hypens: auto

  h4,
  h5
    @apply font-bold

  h1,
  h2,
  h3,
  h4,
  h5
    hyphens: auto
    @screen md
      hyphens: none


  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a
    @apply decoration-2 underline underline-offset-[0.1em] decoration-current transition-all hover:underline-offset-[0.2em]

  ol,
  ul
    @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-1 pl-6 relative
      &:before
        content: '— '
        @apply absolute left-0


