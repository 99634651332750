.button
  @apply uppercase tracking-wider font-bold
  @apply py-2 px-8 md:py-3 md:px-10 xl:text-base
  @apply inline-block rounded-full text-center
  @apply text-white bg-black border border-black dark:text-black dark:bg-white dark:border-white
  @apply hover:bg-white hover:text-black dark:hover:bg-black dark:hover:text-white cursor-pointer

.button-small
  @apply uppercase tracking-wider
  @apply px-4 pb-2 pt-1.5 text-xxs lg:text-xs
  @apply rounded-full text-center
  @apply text-black bg-white border border-black dark:text-white dark:bg-black dark:border-white
  @apply hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black cursor-pointer
