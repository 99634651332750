.hero
  &--wrapper
    height: 100vh
    height: var(--vh)
    @apply relative

  &--message
    @apply bg-black dark:bg-white text-white dark:text-black
    @apply p-4 md:py-6 md:px-8 text-center
    @apply absolute top-[var(--hh)] left-0 w-full z-50
    @apply uppercase tracking-wider font-bold


  &--images,
  &--figure
    @apply h-full relative z-10

  &--images *
    @apply h-full

  &--img
    @apply absolute top-0 left-0 w-full h-full object-cover
