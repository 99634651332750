@font-face {
  font-family: "Noto";
  src: url("/assets/fonts/noto-sans-jp-v52-latin-regular.woff2") format("woff2"), url("/assets/fonts/noto-sans-jp-v52-latin-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url("/assets/fonts/noto-sans-jp-v52-latin-700.woff2") format("woff2"), url("/assets/fonts/noto-sans-jp-v52-latin-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
