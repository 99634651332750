.header
  @apply p-4 md:p-6 xl:p-8
  @apply transition-[padding]
  &.has-scrolled
    @apply py-3 md:py-4 xl:py-6

  &-logo
    @apply w-44 md:w-72 xl:w-80
    @apply transition-[width]

    .has-scrolled &
      @apply w-32 md:w-48 xl:w-64
