.embed

  &--wrapper
    @apply bg-neutral
    &.embeds-iframe
      &:not(.embed-content-loaded)
        .embed--inner
          padding-top: unset !important
          @screen md
            padding-top: 52.65% !important
          .embed--info
            position: static !important
            transform: none !important
            @screen md
              position: absolute !important
              top: 50% !important
              left: 50% !important
              transform: translate(-50%, -50%) !important
      &.embed-content-loaded
        .embed--inner
          position: relative
          padding-top: 50%
          iframe
            position: absolute
            z-index: 10
            top: 0
            left: 0
            width: 100%
            height: 100%


  &--info
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding: 1rem
    max-width: 40rem


  &--footer
    padding: 0.5rem

  &--toggle
    padding-left: 0
    .icon--wrapper
      display: inline-block
      vertical-align: middle
      margin-right: 0.25rem
    svg
      path,
      circle
        fill: var(--color-primary)
