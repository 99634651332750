.slider
  @apply flex flex-nowrap overflow-hidden

.tns-liveregion
  display: none !important

.tns-controls
  height: 0

  button
    @apply text-black hover:text-white active:text-white
    @apply dark:text-white dark:hover:text-black dark:active:text-black
    @apply absolute z-30 top-1/2 -translate-y-1/2 transition-colors
    @apply text-xl xl:text-2xl
    -webkit-tap-highlight-color: transparent !important
    outline: none !important

    &[data-controls="prev"]
      @apply left-2 md:left-8
    &[data-controls="next"]
      @apply right-2 md:right-8
